* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: rgb(23, 58, 58);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #070607;
}

.booklist {
  width: 90vw;
  max-width: 1170px;
  margin: 5rem auto;
  display: grid;
  gap: 2rem;
}

@media screen and (min-width: 768px) {
  .booklist {
    grid-template-columns: repeat(3, 1fr);
    align-items: start;
  }
}
.book {
  position: relative;
  background: #fff;
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
}
.book img {
  width: 100%;
  object-fit: cover;
}
.book h2 {
  margin-top: 1rem;
  font-size: 1rem;
}

.author {
  color: #333;
  font-size: 0.75rem;
  margin-top: 0.5rem;
}
.number {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1rem;
  font-weight: 400;
  padding: 0.75rem;
  border-top-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  background-color: chocolate;
  color: aliceblue;
}

.heading {
  text-align: center;
  position: absolute;
  inset: 0;
  text-transform: capitalize;
  font-size: 1.35rem;
  padding: 0.75rem;
  color: aliceblue;
}
